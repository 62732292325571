<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- title="Gestion des Ressources Humaines" -->
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Grands Livres</span>
          </template>

          <b-card-text>
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="4"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left"
                  style="margin-left: 0px; padding-left: 0px"
                >
                  <label>Afficher</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>Elements par page</label>
                </b-col>

                <!-- Filters -->

                <list-data-table-filters
                  :account-nature-filter.sync="accountNatureFilter"
                  :account-nature-options="accountNatureOptions"
                />

                <!-- Search -->
                <b-col cols="12" md="4">
                  <div
                    class="d-flex align-items-center justify-content-end pull-right"
                  >
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Rechercher..."
                    />
                    <b-button variant="primary" :to="{ name: 'accountinggreatbook-add-new' }">
                      <!-- @click="isAddNewDataSidebarActive = true" -->
                      <span class="text-nowrap">Nouveau Compte</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

            <b-table
              ref="refDataListTable"
              class="position-relative"
              :items="fetchModuleAccountPlans"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="Aucun resultat trouvé"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveDataStatusVariant(
                    data.item.status
                  )}`"
                  class="text-capitalize"
                >
                  <span v-if="data.item.status == 0">
                    En Attente
                  </span>
                  <span v-if="data.item.status == 1">
                    Confirmé
                  </span>
                  <span v-if="data.item.status == 2">
                    En Cours
                  </span>
                  <span v-if="data.item.status == 3">
                    Cloturé
                  </span>
                  <span v-if="data.item.status == 4">
                    Annulé
                  </span>
                  <span v-if="data.item.status == 5">
                    Suspendu
                  </span>
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <!-- <feather-icon
              :id="`accountinggreatbook-row-${data.item.id}-send-icon`"
              icon="SendIcon"
              class="cursor-pointer"
              size="16"
            />
            <b-tooltip
              title="Send accountinggreatbook"
              class="cursor-pointer"
              :target="`accountinggreatbook-row-${data.item.id}-send-icon`"
            /> -->

                  <feather-icon
                    :id="`accountinggreatbook-row-${data.item.id}-preview-icon`"
                    icon="EyeIcon"
                    size="16"
                    class="mx-1"
                    @click="
                      $router.push({
                        name: 'accountinggreatbook-view',
                        params: { id: data.item.id },
                      })
                    "
                  />
                  <b-tooltip
                    title="Visionner accountinggreatbook"
                    :target="`accountinggreatbook-row-${data.item.id}-preview-icon`"
                  />

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'accountinggreatbook-view',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Détails</span>
                    </b-dropdown-item> 

                    <b-dropdown-item
                       v-if="data.item.status == 5"
                      @click="onReActivateQuestion(data.item)"
                      @refetch-data="refetchData"
                    >
                      <feather-icon icon="RefreshIcon" />
                      <span class="align-middle ml-50">Continuer</span>
                    </b-dropdown-item> 

                    <b-dropdown-item
                      v-if="data.item.type != `PROJET`"
                      :to="{
                        name: 'accountinggreatbook-edit',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Modifier</span>
                    </b-dropdown-item>
                    
                    <b-dropdown-item
                      v-if="data.item.type == `PROJET`"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Via Projet</span>
                      <feather-icon icon="DangerIcon" />
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="data.item.type != `PROJET`"
                      @click="onDeleteQuestion(data.item)"
                      @refetch-data="refetchData"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Supprimer</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
                    {{ dataMeta.of }} Enregistements</span
                  >
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalDatas"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>

    </b-card>

    <!-- :plan-filter.sync="planFilter" -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0"> </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";

// sweet alert
import Ripple from "vue-ripple-directive"; 

// tabs 
import { BTabs, BTab, BCardText } from "bootstrap-vue"; 

export default {
  components: {
    ListDataTableFilters, 

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable, 
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // alerts  
    BTabs,
    BCardText,
    BTab,
  },
  directives: {
    Ripple,
  },
  props: {},
  methods: {
    onDeleteQuestion(accountinggreatbook) {
      this.$swal({
        title: "Action Irreversible",
        text: "Voulez-vous vraiment supprimer ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Suppression]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onDelete(accountinggreatbook);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
    onSuspendQuestion(accountinggreatbook) {
      this.$swal({
        title: "Action Importante",
        text: "Voulez-vous vraiment suspendre ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Suspenssion]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onSuspend(accountinggreatbook);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
    onUnSuspendQuestion(accountinggreatbook) {
      this.$swal({
        title: "Action Importante",
        text: "Voulez-vous vraiment réactiver ce accountinggreatbook ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Réactivation]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onUnSuspend(accountinggreatbook);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-accountinggreatbook";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onDelete = (accountinggreatbook) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/deleteModule", accountinggreatbook.id)
        .then(() => {
          // show confirmation accountinggreatbook
          refetchData();
        });
    };    

    const onSuspend = (accountinggreatbook) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/suspendModule", accountinggreatbook.id)
        .then(() => {
          // show confirmation accountinggreatbook
          refetchData();
        });
    }; 

    const onUnSuspend = (accountinggreatbook) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/unsuspendModule", accountinggreatbook.id)
        .then(() => {
          // show confirmation accountinggreatbook
          refetchData();
          /// 
        });
    };

    const isAddNewDataSidebarActive = ref(false); 

    const accountNatureOptions = [
      { label: "--Afficher Tout--", value: "" },
      { label: "Charge", value: "Charge" },
      { label: "Capitaux", value: "Capitaux" },
      { label: "Amortis./Provision", value: "Amortis./Provision" },
      { label: "Aucune", value: "Aucune" },
      { label: "Résultat-Bilan", value: "Résultat-Bilan" },
      { label: "Résultat-Gestion", value: "Résultat-Gestion" },
      { label: "Stock", value: "Stock" },
      { label: "Produit", value: "Produit" },
      { label: "Immobilisation", value: "Immobilisation" },
      { label: "Fournisseur", value: "Fournisseur" },
      { label: "Client", value: "Client" },
      { label: "Salarié", value: "Salarié" },
      { label: "Titre", value: "Titre" },
      { label: "Banque", value: "Banque" },
      { label: "Caisse", value: "Caisse" }, 
    ];

    ////////////// autres ajouts /////////////////

    const resolveAccountPlanVariant = (direction) => {
      if (direction === "in") return "primary";
      if (direction === "out") return "warning";
      return "primary";
    };

    const resolveAccountPlanIcon = (direction) => {
      if (direction === "in") return "ArrowDownIcon";
      if (direction === "out") return "ArrowUpIcon";
      return "UserIcon";
    };

    const {
      fetchModuleAccountPlans,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      accountNatureFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleAccountPlans,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      onDelete,

      // Filter
      avatarText,

      // UI
      resolveAccountPlanIcon,
      resolveAccountPlanVariant,
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      accountNatureOptions,

      // Extra Filters
      accountNatureFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
